
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Fragment, type FC } from 'react';
import type { GetServerSideProps } from 'next';
import useTranslation from 'next-translate/useTranslation';
import Link from '@components/Link';
import styled from '@emotion/styled';
import { PromotedPostsDocument, type PromotedPostsQuery, } from '@graphql/generated';
import { query } from '@graphql/withApollo';
import { Content } from '@components/Layout';
import SEO from '@components/SEO';
import { BlogCard } from '@components/blog/Card';
import { CardGrid, CardWrapper } from '@components/Card';
const ButtonLink = styled(Link) `
  display: inline-block;
  background-color: rgb(var(--color-primary));
  color: rgb(var(--color-background));
  text-decoration: none;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
  transition: transform 60ms ease-in-out;

  &:hover,
  &:focus,
  &:visited {
    color: rgb(var(--color-background));
    transform: scale(1.03);
  }
`;
const Home: FC<{
    data: PromotedPostsQuery;
}> = ({ data }) => {
    const { t } = useTranslation('common');
    const promotedPosts = data?.rubriques.flatMap((arr) => arr.promotedPosts);
    /* eslint-disable i18next/no-literal-string */
    return (<Fragment>
      <SEO title={t('home:title')}/>

      <Content className="prose max-w-none">
        <h1>{t('home:title')}</h1>

        <p>
          My name is Ousmane Ndiaye, I am a full stack developer/platform
          engineer with a passion for creating high-quality and innovative
          software. I enjoy tackling complex projects and turning them into
          successful solutions.
        </p>

        <p>
          On this website, you will find my resume, which showcases my
          experiences and skills, as well as my blog, where I share my thoughts
          and experiences as a developer.
        </p>

        <p>
          Feel free to take a look around and get to know me and my work. If you
          have any questions or would like to collaborate on a project,
          don&rsquo;t hesitate to reach out through the{' '}
          <ButtonLink href="/contact">contact page</ButtonLink>.
        </p>

        <p>Thank you for visiting!</p>
      </Content>

      <Content className="fullscreen">
        <section className="my-2">
          <h2 className="text-center text-xl font-bold">
            {t('home:promoted_posts')}
          </h2>
          <CardGrid>
            {promotedPosts.map((post) => (<CardWrapper key={post.id}>
                <BlogCard {...post}/>
              </CardWrapper>))}
          </CardGrid>
        </section>
      </Content>
    </Fragment>);
};
const getStaticProps: GetServerSideProps = async ({ params }) => {
    const variables = {
        slug: params?.slug as string,
    };
    const { data, errors } = await query(PromotedPostsDocument, variables);
    if (errors) {
        return {
            props: {
                data: {},
                errors,
            },
            revalidate: 3000,
        };
    }
    return {
        props: { data },
        revalidate: 60000,
    };
};
export default Home;

    async function __Next_Translate__getStaticProps__1898874f657__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1898874f657__ as getStaticProps }
  